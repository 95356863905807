<template>
    <section style="height: calc(100vh - 65px);">
        <div v-if="permitido" class="row mx-0 h-100">
            <div class="menu-prinicipal h-100">
                <div class="border col br-10 " style="width:400px;height:calc(100% - 55px);">
                    <!-- listado pedidos -->
                    <div v-show="pedidos.length" class="row m-3 p-2 px-3 br-10 border bg-whitesmoke justify-center">
                        <span> Pedido</span>
                    </div>
                    <div class="mb-2 overflow-auto custom-scroll" style="max-height: calc(100% - 120px);">
                        <div v-for="(ped, idx) in pedidos" :key="idx" class="row mx-0 d-middle my-2 border-bottom pb-2">
                            <div :class="`mx-2 px-1 br-5 ${activo==ped.id?'bg-gr-red':''}`" style="height:75px;" />
                            <div :class="`col-11 px-0 tres-puntos f-15 br-10 ${activo==ped.id?'bg-whitesmoke':''}`">
                                <div class="row mx-0">
                                    <div class="col-7 px-2 pl-4 d-middle">
                                        <i v-show="[32,33,34].indexOf(ped.estado) > -1" class="icon-attention-alt text-danger f-18" />
                                        <p class="tres-puntos">ID:{{ ped.id }}</p>
                                    </div>
                                    <div class="col-5 px-1 tres-puntos text-right">
                                        <i :class="`f-18 ${icon_estado(ped.estado)}`" />
                                        {{ estado_pedido(ped.estado) }}
                                    </div>
                                </div>
                                <div class="row px-3 mx-0 my-1">
                                    <div class="col-8 px-2 text-orange f-600">
                                        <p class="tres-puntos">{{ separadorNumero(ped.valor_final) }}</p>
                                    </div>
                                    <div class="col-4 px-1">
                                        <b class="mr-1">
                                            {{ ped.cant_productos }}
                                        </b>
                                        Productos
                                    </div>
                                </div>
                                <div class="row px-3 mx-0">
                                    <div class="col-8 px-2 d-middle">
                                        <p class="tres-puntos">{{ ped.direccion }}</p>
                                    </div>
                                    <div class="col-4 px-1">
                                        <b class="mr-1">
                                            {{ ped.distancia }} mts
                                        </b>
                                        aprox.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 border col br-t-10 menu-prinicipal  h-100 ml-3" style="width: calc(100vw - 560px);">
                <section class="h-100">
                    <div class="row mx-0 p-2 py-3 px-3 border-bottom align-items-center">
                        <template>
                            <p v-show="[4,32,33,34].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Entregado</p>
                            <p v-show="[23,27].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Cancelado Por Cliente</p>
                            <p v-show="pedido.estado === 25"><b>ID. {{ pedido.id }} </b>Pedido Rechazado</p>
                            <p v-show="[21,22,24,26].indexOf(pedido.estado) > -1"><b>ID. {{ pedido.id }} </b>Pedido Cancelado</p>


                            <div v-show="pedido.manual === 0" class="d-middle ml-auto">
                                <div v-show="[21,22,24,26].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                                    <i class="text-danger f-18 icon-cancel-circled-outline" />
                                    <p>Cancelado</p>
                                    <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                                </div>
                                <div v-show="[23,27].indexOf(pedido.estado) > -1" class="d-middle border-orange px-1 br-10 f-15">
                                    <i class="text-danger f-18 icon-account-remove-outline" />
                                    <p>Cancelado</p>
                                    <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                                </div>
                                <div v-show="pedido.estado === 25" class="d-middle border-orange px-1 br-10 f-15">
                                    <i class="text-danger f-18 icon-playlist-remove" />
                                    <p>Rechazado</p>
                                    <p>({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                                </div>
                                <div v-show="[32,33,34].indexOf(pedido.estado) > -1" class="bg-grr-red text-white d-middle br-10 px-1 cr-pointer">
                                    <i class="icon-attention-alt f-18" />
                                    <p class="mx-2">Reclamo reportado</p>
                                    <i class="icon-angle-right f-18" />
                                </div>
                                <el-tooltip class="item" effect="light" content="Hablar con el cliente" placement="bottom">
                                    <el-badge :value="pedido.cant_mensajes" class="item mx-2 mr-4">
                                        <i class="icon-message-text cr-pointer f-20 text-gr-general" @click="verChat" />
                                    </el-badge>
                                </el-tooltip>
                            </div>
                            <div v-show="pedido.manual === 1" class="d-middle ml-auto">
                                <div class="d-middle border-orange px-1 br-10 f-15">
                                    <i class="text-danger f-18 icon-account-reactivate" />
                                    <p>Manual </p>
                                    <p> ({{ pedido.updated_at | helper-fecha('DD/MM/Y - hh:mm a') }})</p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row mx-0" style="height:calc(100% - 65px);">
                        <div class="col-7 px-0 h-100 border-right">
                            <div class="p-3 f-15 h-100 overflow-auto custom-scroll">
                                <div class="row mx-0 justify-center">
                                    <card-producto-pedido
                                    v-for="(prod, idx) in productos" :key="`prod-${idx}`"
                                    :info="prod"
                                    class="cr-pointer"
                                    @accion="modalProducto"
                                    />
                                </div>

                                <contentLoader v-show="loading" />
                            </div>
                        </div>
                        <div class="col-5 px-0 h-100 overflow-auto custom-scroll">
                            <!-- <detalle-historial :pedido="pedido" /> -->
                            <section>
                                <div class="border-bottom">
                                    <div class="row mx-0 align-items-center my-2 px-2">
                                        <img class="border rounded-circle bg-whitesmoke" :src="cliente.avatar" width="50" height="50" alt="" />
                                        <p class="mx-2 f-600">{{ cliente.nombre }}</p>
                                        <div class="br-20 px-2 mx-2 text-white " :style="{'background':color_fondo(cliente.cant_pedidos)}">
                                            <i class="f-20 icon-receipt" />
                                            <span class="mx-2">{{ cantidad }}</span>
                                        </div>
                                    </div>
                                    <div class="row mx-0 my-2 px-2 align-items-center">
                                        <i class="icon-location text-gris f-20 mr-2" />
                                        <p class="col">{{ pedido.direccion }}</p>
                                    </div>
                                    <div class="row mx-0 my-2 px-2 align-items-center">
                                        <i class="icon-arrow-top-right text-gris f-18 mr-2" />
                                        <p class="col">{{ pedido.distancia }} Mts</p>
                                    </div>
                                    <div class="row mx-0 my-2 px-2 align-items-center">
                                        <i class="icon-calendar-clock text-gris f-22 mr-2" />
                                        <p class="col f-15">{{ pedido.entrega_fecha | helper-fecha('ddd DD MMMM') }}</p>
                                    </div>
                                    <div class="row mx-0 my-2 px-2 align-items-center">
                                        <i class="icon-truck-fast-outline text-gris f-22 mr-2" />
                                        <p class="col f-15">{{ convertMoney(pedido.valor_envio, $tienda.idm_moneda) }}</p>
                                    </div>
                                    <div class="row ml-3 mr-0 d-middle">
                                        <span class="text-gr-general mr-2">Creditos</span>
                                        <el-tooltip placement="top" content="Deuda Vigente" effect="light">
                                            <i class="icon-clock text-gr-general f-18" />
                                        </el-tooltip>
                                        <span class="f-14 mr-2">{{ convertMoney(pedido.saldo_vigente,$tienda.idm_moneda) }}</span>

                                        <el-tooltip placement="top" content="Deuda Vencida" effect="light">
                                            <i class="icon-attention-alt text-danger f-18" />
                                        </el-tooltip>
                                        <span class="f-14">{{ convertMoney(pedido.saldo_vencido,$tienda.idm_moneda) }}</span>
                                    </div>
                                </div>
                                <!-- linea de tiempo -->
                                <div class="border-bottom py-2">
                                    <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                                        <div class="col-auto">
                                            <div class="rounded-circle position-relative" :class="act.activo?'bg-gr-general':'bg-gris'" style="width:44px;height:44px;padding:1px">
                                                <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                                    <i :class="`f-20 ${act.icon} ${act.activo?'text-gr-general':'text-gris'}`" />
                                                </div>
                                            </div>
                                            <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="act.activo?'bg-gr-general':'bg-gris'" style="width:2px;" />
                                        </div>
                                        <div class="col">
                                            <div class="row mx-0">
                                                <div class="col px-1">
                                                    <p :class="act.activo?'text-primary':'text-gris'">{{ act.content }}</p>
                                                    <p class="f-15">{{ act.hora | helper-fecha('hh:mm a') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="border-bottom py-2">
                                    <div class="row mx-0 px-2 cr-pointer" @click="verInstrucciones">
                                        <i class="icon-card-text-outline f-20 text-gr-general" />
                                        <div class="col my-auto">
                                            Instrucciones de entrega
                                        </div>
                                        <i class="icon-angle-right f-20 text-gr-red" />
                                    </div>
                                </div>
                                <div class="p-2">
                                    <div class="row mx-0 px-2 text-orange f-600 f-20">
                                        Resumen
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Costo de productos ({{ resumenes.inicial.cantidad_productos }})</p>
                                        <b>{{ separadorNumero(resumenes.inicial.val_productos) }}</b>
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Costo de envío</p>
                                        <b>{{ separadorNumero(resumenes.inicial.val_domicilios) }}</b>
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Descuento</p>
                                        <b class="text-orange">
                                            {{ separadorNumero(resumenes.inicial.val_descuentos) }}
                                        </b>
                                    </div>
                                    <div class="row mx-0 px-2 mt-2 f-600 justify-content-between">
                                        <p>Total a cobrar</p>
                                        <p>{{ separadorNumero(resumenes.inicial.val_total) }}</p>
                                    </div>
                                </div>
                                <div v-show="[110,11,12,13].indexOf(pedido.estado) > -1" class="p-2">
                                    <div class="row mx-0 px-2 text-primary f-600 f-20">
                                        Nuevo resumen
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Costo de productos ({{ resumenes.final.cantidad_productos }})</p>
                                        <b>{{ separadorNumero(resumenes.final.val_productos) }}</b>
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Costo de envío</p>
                                        <b>{{ separadorNumero(resumenes.final.val_domicilios) }}</b>
                                    </div>
                                    <div class="row mx-0 px-2 justify-content-between">
                                        <p>Descuento</p>
                                        <b class="text-primary">
                                            {{ separadorNumero(resumenes.final.val_descuentos) }}
                                        </b>
                                    </div>
                                    <div class="row mx-0 px-2 mt-2 f-600 justify-content-between">
                                        <p>Total a cobrar</p>
                                        <p>{{ separadorNumero(resumenes.final.val_total) }} </p>
                                    </div>
                                </div>
                                <div v-if="pedido.pago_estado != 1" class="row mx-0 mt-3 mb-3 px-1">
                                    <div class="col-12 px-0">
                                        <div v-show="pedido.pago_estado === 2 || pedido.pago_estado === 3" class="bg-general text-white header-credito d-middle-center f-600 f-15">
                                            Crédito
                                        </div>
                                        <div v-show="pedido.pago_estado === 4" class="bg-gris header-credito d-middle-center f-600 f-15">
                                            Crédito Anulado
                                            <el-tooltip placement="bottom" :content="pedido.pago_anulado" effect="light">
                                                <i class="icon-attention-alt f-18 text-gr-general ml-5" />
                                            </el-tooltip>
                                        </div>
                                        <div class="borders pt-3" style="min-height:150px;">
                                            <p class="text-muted mb-2 f-15 f-600 text-center">
                                                Cuotas establecidas
                                            </p>
                                            <div v-for="(cuota, c) in cuotas" :key="c" class="row mx-3" :class="colorTextoCredito(cuota)">
                                                <p class="tres-puntos border-bottom border-top py-2 col">
                                                    <i class="f-18" :class="iconCredito(cuota)" />
                                                    <span class="f-15">
                                                        {{ cuota.fecha | helper-fecha('DD/MMM/YYYY') }}
                                                    </span>
                                                </p>
                                                <p class="tres-puntos border-bottom border-top py-2 col-auto f-600">
                                                    {{ convertMoney(cuota.valor,pedido.idm_moneda) }}
                                                </p>
                                            </div>
                                            <p class="text-muted my-2 f-15 f-600 text-center">
                                                Historial de Pagos
                                            </p>
                                            <div v-for="(p, i) in pagos" :key="`key2-${i}`" class="row mx-3 border-bottom mb-3">
                                                <p class="col f-14 tres-puntos">
                                                    {{ p.created_at | helper-fecha('DD MMMM YYYY') }}
                                                </p>
                                                <div class="col-auto">
                                                    <el-tooltip placement="bottom" :content="p.comentario" effect="light">
                                                        <i class="icon-attention-alt f-18 text-gr-general" />
                                                    </el-tooltip>
                                                </div>
                                                <p class="col-12 f-14">
                                                    {{ convertMoney(p.valor,pedido.idm_moneda) }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <modalVerInstrucciones ref="modalVerInstrucciones" />
                            </section>
                        </div>
                    </div>
                    <!-- <modalProductoDesconocido ref="modalProductoDesconocido" /> -->
                </section>
            </div>
        </div>
        <div v-else class="row mx-0">
            <div class="col text-center">
                <p>No tiene permisos para ver esta vista</p>
            </div>
        </div>
        <modalDetalleProd ref="modalDetalleProd" />
        <modalChat ref="modalChat" @actualizarIcono="actualizarIconoChat" />
        <modalVerInstrucciones ref="modalVerInstrucciones" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import TiendaPedidos from '~/services/pedidos/pedidos-tienda'
import Pedido from '~/services/pedidos/pedidos-tienda'
import PedidoHistorial from '~/services/pedidos/pedidos-historial'
import moment from 'moment'

export default {
    metaInfo: () =>({ title: 'Historial'}),
    components: {
        modalDetalleProd: () => import('~/pages/almacen/pedidos/partials/modalDetalleProducto'),
        modalVerInstrucciones: () => import('~/pages/almacen/pedidos/partials/modalVerInstrucciones')
    },
    data(){
        return {
            id_pedido: this.$route.params.id_pedido,
            buscar: '',
            activo: -1,
            pagina_actual:1,
            pedidos: [],
            productos: [],
            pedido:{
                estado: null,
                cant: null,
                updated_at: null,
                cant_mensajes: 0


            },
            editar: false,
            verDetalle: 0,
            estadoPedido: { estado: 'Reportado', icono: 'icon-cancel-circled-outline' },
            loading:false,
            cliente:{
                nombre: null,
                direccion: null,
                distancia: null
            },
            historial:[],
            resumenes:{inicial:{},final:{}},
            permitido: false,
            cuotas:[],
            pagos:[]

        }
    },
    computed:{
        rol(){
            return this.$usuario.rol_nombre
        },
        cantidad(){
            let cant = this.cliente.cant_pedidos
            if(cant === 0){
                return 0
            }else if(cant > 0 && cant <= 5){
                return '1 - 5'
            }
            else if(cant > 5 && cant <= 20){
                return '+5'
            }
            else if(cant > 20 && cant <= 100){
                return '+20'
            }
            else if(cant > 100){
                return '+100'
            }else{
                return 0
            }
        }
    },
    mounted(){
        this.get_datos()
        this.listar_pedidos()
        this.listar_productos()
        this.historial_estados()
        this.listar_credito()
        this.listar_resumenes()
    },
    methods: {
        async get_datos(){
            try {
                const {data} = await Pedido.get_datos(this.id_pedido)
                this.cliente = data.cliente
                this.pedido = data.pedido
            } catch (e){
                this.error_catch(e)
            }

        },
        async listar_pedidos(){
            try {
                if(this.id_pedido){
                    var {data} = await Pedido.consultar_un_pedido(this.id_pedido)
                    this.permitido = data.permitido
                    this.pedidos.push(data.pedido)



                    // var {data} = await Pedido.pedidos_productos(this.id_pedido)
                    // this.productos = data.productos
                    // this.cliente = data.cliente
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_productos(){
            try {
                if(this.id_pedido){
                    const {data} = await Pedido.pedidos_productos(this.id_pedido)
                    console.log('listar_productos', data);
                    // this.pedido = data.pedido
                    this.productos = data.productos
                    // this.cliente = data.cliente
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async historial_estados(){
            try {
                const {data} = await TiendaPedidos.historial_estados(this.id_pedido)
                let payload = data
                let mensajes = [
                    'Un cliente ha solicitado un pedido',
                    'Pedido Confirmado',
                    'Pedido con cambios',
                    'Pedido En Alistamiento',
                    'Pedido En Empaque',
                    'Pedido Enviado',
                    'Pedido Entregado',
                    'Pedido Cancelado',
                    'Reclamo',
                ]

                let iconos = [
                    'icon-receipt',
                    'icon-ok-circled',
                    'icon-account-edit',
                    'icon-cart-arrow-down',
                    'icon-shopping',
                    'icon-truck-fast-outline',
                    'icon-shopping',
                    'icon-attention-outline',
                ]

                let history = []
                for (let idx in payload){
                    if(idx == 2 || idx == 7 || idx == 8){

                        if(payload[idx] !== null){
                            let estado = state.pedido.estado
                            // console.log(estado);
                            let icon = ''
                            switch (estado){
                            case 25:
                                icon = 'icon-playlist-remove'
                                break;
                            case 26:
                                icon = 'icon-cancel-circled-outline'
                                break;
                            case 27:
                                icon = 'icon-account-remove-outline'
                                break;
                            default:
                                icon ='icon-attention-outline'
                            }

                            history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:icon })
                        }
                    }else{
                        history.push({ content:mensajes[idx], hora:payload[idx], activo: payload[idx] !== null, icon:iconos[idx] })
                    }
                }
                this.historial = history
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_resumenes(){
            try {
                const {data} = await TiendaPedidos.resumenes(this.id_pedido)
                this.resumenes = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async listar_credito(){
            try {
                const {data} = await  TiendaPedidos.get_credito(this.id_pedido)
                this.cuotas = data.cuotas
                this.pagos = data.pagos
            } catch (e){
                this.error_catch(e)
            }
        },
        colorTextoCredito(cuota){ // ✔
            if(cuota.valor_pendiente == 0){
                return 'text-primary'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'text-red'
            }
            return ''
        },
        iconCredito(cuota){ // ✔

            if(cuota.valor_pendiente == 0){
                return 'icon-ok-circled-outline text-green'
            }

            const hoy = moment().format('YYYY-MM-DD')
            if(cuota.fecha < hoy){
                return  'icon-attention-alt text-red'
            }

            return ''
        },
        //////////////////////////////////////////
        icon_estado(estado){
            switch (estado){
            case 4:
                return 'icon-shopping text-gr-general'
            case 21:
            case 22:
            case 24:
            case 26:
                return 'icon-cancel-circled-outline text-danger'
            case 23:
            case 27:
                return 'icon-account-remove-outline text-danger'
            case 25:
                return 'icon-playlist-remove text-danger'
            case 32:
            case 33:
            case 34:
                return 'icon-shopping text-gr-general'
            default:
                return ''
            }
        },
        estado_pedido(estado){
            switch (estado){
            case 32:
            case 33:
            case 34:
            case 4:
                return 'Entregado'
            case 21:
            case 22:
            case 24:
            case 26:
                return 'Cancelado'
            case 23:
            case 27:
                return 'Cancelado'
            case 25:
                return 'Rechazado'
            default:
                return ''
            }
        },
        modalProducto(prod){
            if(prod.desconocido === 1){
                this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                return
            }
            this.$refs.modalDetalleProd.datos_cambio(prod) // modal detalle producto
        },
        verChat(){
            let soloVisual = false
            this.$refs.modalChat.toggle(this.id_pedido, 5, soloVisual) // 5 = tipo pedido admin
        },
        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            let idx_pedido = this.pedidos.findIndex(e => e.id === this.id_pedido)

            if(idx_pedido >= 0){
                let found = this.pedidos[idx_pedido]
                found.cant_mensajes = 0
            }
        },
        verInstrucciones(){
            this.$refs.modalVerInstrucciones.toggle(this.pedido)
        },
        color_fondo(cant){ // ✔
            if(cant === 0){
                return '#FF0000'
            }else if(cant > 0 && cant <= 5){
                return '#FCA105'
            }
            else if(cant > 5 && cant <= 20){
                return '#00C93C'
            }
            else if(cant > 20 && cant <= 100){
                return '#8701AE'
            }
            else if(cant > 100){
                return '#0028C9'
            }

        },

    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{
    background: #fff;
}
</style>
